import React from "react";
import { Link } from "react-router-dom";
import "./scroll-horizontal.css"; // Import your CSS file
const ScrollBannerHorizontal = () => {
  return (
    <Link className="top-albums" to="/top-albums-2023">
      <div className="scroll-container">
        <div className="scroll-text">
          <p>
            LIVE 24/7<p className="oe"> œ </p>BEST ALBUMS 2023 CLICK HERE
            <p className="oe"> œ </p>
            LIVE 24/7<p className="oe"> œ </p>BEST ALBUMS 2023 CLICK HERE
            <p className="oe"> œ </p>
            LIVE 24/7<p className="oe"> œ </p>BEST ALBUMS 2023 CLICK HERE
            <p className="oe"> œ </p>
            LIVE 24/7<p className="oe"> œ </p>BEST ALBUMS 2023 CLICK HERE
            <p className="oe"> œ </p>
            LIVE 24/7<p className="oe"> œ </p>BEST ALBUMS 2023 CLICK HERE
            <p className="oe"> œ </p>
            LIVE 24/7<p className="oe"> œ </p>BEST ALBUMS 2023 CLICK HERE
            <p className="oe"> œ </p>
            LIVE 24/7<p className="oe"> œ </p>BEST ALBUMS 2023 CLICK HERE
            <p className="oe"> œ </p>
            LIVE 24/7<p className="oe"> œ </p>BEST ALBUMS 2023 CLICK HERE
            <p className="oe"> œ </p>
          </p>
          <p>
            LIVE 24/7<p className="oe"> œ </p>BEST ALBUMS 2023 CLICK HERE
            <p className="oe"> œ </p>
            LIVE 24/7<p className="oe"> œ </p>BEST ALBUMS 2023 CLICK HERE
            <p className="oe"> œ </p>
            LIVE 24/7<p className="oe"> œ </p>BEST ALBUMS 2023 CLICK HERE
            <p className="oe"> œ </p>
            LIVE 24/7<p className="oe"> œ </p>BEST ALBUMS 2023 CLICK HERE
            <p className="oe"> œ </p>
            LIVE 24/7<p className="oe"> œ </p>BEST ALBUMS 2023 CLICK HERE
            <p className="oe"> œ </p>
            LIVE 24/7<p className="oe"> œ </p>BEST ALBUMS 2023 CLICK HERE
            <p className="oe"> œ </p>
            LIVE 24/7<p className="oe"> œ </p>BEST ALBUMS 2023 CLICK HERE
            <p className="oe"> œ </p>
            LIVE 24/7<p className="oe"> œ </p>BEST ALBUMS 2023 CLICK HERE
            <p className="oe"> œ </p>
          </p>
        </div>
      </div>
    </Link>
  );
};

export default ScrollBannerHorizontal;
